import React from 'react'
import { Link } from 'gatsby'
import './Nav.css'

const I18nData = {
  en: {
    Home: 'Home',
    Calendar: 'Calendar / Events',
    Photos: 'Photos / Videos',
    Address: 'Address / Parking',
    Contact: 'Contact Us'
  },
  vn: {
    Home: 'Trang Chính',
    Calendar: 'Lịch / Sự Kiện',
    Photos: 'Hình / Phim',
    Address: 'Địa Chỉ',
    Contact: 'Liên Lạc'
  }
}
let I18n = I18nData[(typeof window !== 'undefined' && localStorage.getItem('lang')) || 'vn'] // default to 'vn'

export default () => {
  return (
    <nav className="nav">
      <Link to="/">{I18n.Home}</Link>
      <Link to="/calendar/">{I18n.Calendar}</Link>
      <Link to="/photos/">{I18n.Photos}</Link>
      <Link to="/address/">{I18n.Address}</Link>
      <Link to="/contact/">{I18n.Contact}</Link>
    </nav>
  )
}
